import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Icons from '../assets';
import AuthService from '../services/AuthService';
import './AppHeader.scss';

const AppHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogOut = () => {
    AuthService.logout();
    navigate('/login');
  };

  const handleHamburgerClick = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('stop-scrolling');
    } else {
      document.body.classList.remove('stop-scrolling');
    }
  };

  const navigateToContactUs = () => {
    window.open('https://www.rozie.ai/contactus', '_blank');
  };

  const navigateToHome = () => {
    window.open('https://www.rozie.ai/', '_blank');
  };

  const navigateToSolutions = () => {
    window.open('https://www.rozie.ai/solutions', '_blank');
  };

  return (
    <nav className="navbar">
      <div className="navbar__logo">
        <Link to="/videos">
          <img className="navbar-logo-icon" src={isMobile ? Icons.mobileLogo : Icons.roziePurpleBlueLogo} alt="logo" />
        </Link>
      </div>
      <div className="navbar__mobiletitle">
        <span>Rozie Experience</span>
      </div>
      <div className="navbar__links__desktop">
        <Link to="https://www.rozieai.com/" target="_blank">
          Visit RozieAI
        </Link>
        <Link to="/synopsis">Synopsis</Link>
      </div>

      {isMobile && (
        <div className={`navbar__links ${isMenuOpen ? 'navbar__open' : ''}`}>
          <img src={Icons.menuClose} alt="" className="close-button" onClick={handleHamburgerClick} />
          <div className="menu">
            <span className="title">Menu</span>
            <div className="menu-item" onClick={navigateToHome}>
              <span className="text">Experience</span>
              <img src={Icons.keyboardArrowRight} alt="" className="arrow" />
            </div>
            <div className="menu-item" onClick={navigateToSolutions}>
              <span className="text">Solutions</span>
              <img src={Icons.keyboardArrowRight} alt="" className="arrow" />
            </div>
            <div className="menu-item" onClick={navigateToHome}>
              <span className="text">About</span>
              <img src={Icons.keyboardArrowRight} alt="" className="arrow" />
            </div>
            <div className="menu-item" onClick={handleLogOut}>
              <span className="text">Log out</span>
            </div>
            <div className="menu-footer">
              <img src={Icons.rozieLogo} alt="footer img" width="100px" height="20px" />
              <span className="menu-footer-text">© 2024 RozieAI. All rights reserved.</span>
            </div>
          </div>
        </div>
      )}

      <div className="navbar__buttons">
        <button onClick={navigateToContactUs}>Book Consultation</button>
        <button onClick={handleLogOut}>Log Out</button>
      </div>
      <div className="navbar__hamburger" onClick={handleHamburgerClick}>
        <img src={Icons.hamburgerIcon} alt="" />
      </div>
    </nav>
  );
};

export default AppHeader;
